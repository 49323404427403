import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HpCard from "../components/hpcard"
import HPupcomingevents from "../components/hpupcomingevents"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="hp-hero post-hero"
      /* add in this to make hp banner dynamic via contentful
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(28,28,28,1) 0%, rgba(28,28,28,0) 0%, rgba(28,28,28,1) 100%), 
          url(${props.image})`
        }} 
      */
    >

      <div className="hp-hero-group">
        <h1>Museum of Distilled Spirits</h1>
        <p>The first-of-its-kind museum offering virtual tours, storytelling exhibits and interactive educational events focusing on all distilled spirits.</p>
        {/*<Link className="button" to="/hand-sanitizer">Launch Exhibit</Link>*/}
        {/*<a href="#hp"><img src={require("../images/down-arrow-white.png")} alt='down arrow icon.' /></a>*/}
      </div>

    </div>

    <div className="hub-wrapper">

      <div className="hub-section-title center">
          <h2>Upcoming Event Programs</h2>
      </div>
      <div className="hub-cards-section">
          <HPupcomingevents />
      </div>    

      <div className="center">
          <a className="button" href="/events/">See All Events</a>
    </div>

    </div>


    <div className="hub-wrapper">
      <div className="hub-section-title center">
            <h2>Choose Your Spirit</h2>
        </div>
        
      <div className="hub-section hp-cards">
        <HpCard />
      </div>

      <div className="subscribe-group">
          <h2>Stay Updated</h2>
          <p>Learn about new exhibits, courses, and more in our newsletter</p>
          <a className="button" href="https://lp.constantcontactpages.com/su/g8xo2a7">Subscribe</a>
      </div>

    </div>

  </Layout>
)

export default IndexPage

